import { AlertCircle } from "lucide-react";
import { Fragment } from "react";

const ErrorMessage = ({ error }: { error?: string }) => (
  <Fragment>
    {error ? (
      <span className="flex items-center justify-start gap-x-1 pt-1 text-sm text-red-400">
        <AlertCircle className="h-4 w-4" /> {error}
      </span>
    ) : null}
  </Fragment>
);

export { ErrorMessage };
